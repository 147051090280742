<template>
  <chat
    :channel="channel"
    :mobile="isMobile"
    class="chat"
  />
</template>

<script>
import Chat from '../../components/chat/chat.vue'

export default {
  components: {
    Chat,
  },
  data() {
    return {
      isMobile: false,
      channel: '',
      channelData: {},
    }
  },
  created() {
  },
  async mounted() {
    const channel = window.location.pathname.substring(1).replace('/chat', '')
    const channelLookup = await this.channelLookupBySlug(channel)
    document.title = `${channelLookup.slug}'s chat - Brime`;
    this.channel = channelLookup.xid
  },
}
</script>

<style>
.chat__column{
    position: fixed;
    display: flex;
    flex-direction: column;
    top: var(--headerHeight);
    height: 100vh !important;
    width: 100% !important;
    max-height: 100%;
}
.gif__picker.open {
max-width: 400px;
margin-top: 15px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.gif__picker.open::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.gif__picker.open {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
